<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item>
      <v-list-item-content style="margin-bottom: -5px">
        <v-list-item-title class="text-center font-weight-regular">
          <span>
            <a href="/"
              ><img src="@/assets/logo_blanco.svg" class="logoBlanco"
            /></a>
          </span>
          <div style="margin-top: -5px; margin-bottom: -1px">
            <v-chip
              class="mt-1"
              color="white"
              outlined
              style="width: 55px; height: 10px"
            >
              <span style="color: white; font-size: 0.8em">v1.1.0</span>
            </v-chip>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />

    <v-list style="padding-left: 0px" expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-show="verOpciones"
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from 'vuex'

export default {
    name: 'DashboardCoreDrawer',
    components: {
        'base-item': () => import('./Item.vue'),
        'base-item-group': () => import('./ItemGroup.vue')
    },
    props: {
        expandOnHover: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        verOpciones: true,
        items: [

        ]
    }),

    computed: {
        ...mapState(['barColor', 'barImage']),
        drawer: {
            get () {
                return this.$store.state.drawer
            },
            set (val) {
                this.$store.commit('SET_DRAWER', val)
            }
        },
        computedItems () {
            return this.items.map(this.mapItem)
        }

    },
    methods: {
        mapItem (item) {
            return {
                ...item,
                children: item.children ? item.children.map(this.mapItem) : undefined,
                title: item.title
            }
        },
        rol () {
            const Comercial = this.$store.getters.getComercial

            if (Comercial.padre) {
                this.items.push(
                    {
                        group: '',
                        icon: 'mdi-shopping',
                        title: 'Pedidos',
                        children: [
                            {

                                title: 'Crear Pedido',
                                to: 'crearPedido'
                            },
                            {
                                title: ' Ver Pedidos',
                                to: 'verPedidos'
                            }
                        ]
                    })
            } else {
                this.items.push({
                    icon: 'mdi-account',
                    title: 'Comerciales',
                    to: 'comerciales'
                },
                {
                    group: '',
                    icon: 'mdi-shopping',
                    title: 'Pedidos',
                    children: [
                        {

                            title: 'Crear Pedido',
                            to: 'crearPedido'
                        },
                        {
                            title: ' Ver Pedidos',
                            to: 'verPedidos'
                        }
                    ]
                })
            }
        }

    },
    created () {
        this.rol()
    }
}
</script>
